.deco-card {
    height: 550px;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    background-color: #161741;
    z-index: -1;
    border-bottom-left-radius: 20px;
}

.feature-image {
    transform: translateY(30%);
}

.background-deco {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255, 50%);
    bottom: -50px;
    right: 10%;
}

.background-deco::before {
    content: '';
    position: absolute;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 768px) {
    .deco-card {
        clip-path: polygon(0 0, 100% 0%, 100% 83%, 0% 100%);
    }

    .background-deco,
    .background-deco::before {

        display: none;
    }

    .deco-card {
        clip-path: none !important;
        overflow: hidden !important;
    }

    .feature-head {
        height: 400px !important;
    }

    .feature-image {
        transform: translateY(15%) !important;
    }

    .feature-image {
        width: 700px !important;
    }

    .features-title {
        font-size: 40px !important;
        line-height: 60px;
    }

    .features-title {
        transition: .8s;
        animation: anft 2s linear;
    }

    @keyframes anft {
        from {
            color: red !important;
            transform: translateY(30%);
        }
    }


    .feature-card {
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: center !important;
    }

    .feature-img {
        width: 70px !important;
        height: 70px !important;
    }

    .feature-module-title {
        width: 100%;
        margin: 20px 0 !important;
    }
}