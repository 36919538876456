.docs-wrapper {
    margin-bottom: 100px;
}

.doc {
    transition: 1s;
    animation: doc 1s forwards;
}

@keyframes doc {
    from {
        transform: rotate(-3deg);
    }
}

.doc img {
    transition: 1s;
}

.doc:hover img {
    transform: scale(1.2);
}

.doc .doc-overlay {
    transition: all 1s;
    cursor: pointer;
}

.doc:hover {
    transition: .5s;
    background: transparent;
}

.doc:hover .doc-overlay {
    top: 0;
    background: linear-gradient(-238deg, rgb(0, 0, 0, 50%), rgb(0, 0, 0, 70%));
}

.title-block {
    width: 0%;
    background-color: white;
    height: 4px;
    margin: 20px 0;
    transition: .6s;
    opacity: 0;
    border-radius: 50%;
}

.title-block::before {
    content: '';
    position: absolute;
    left: -10px;
    width: 20px;
    height: 20px;
    background-color: white;
    top: -8px;
    border-radius: 50%;
    transition: .8s;
    transform: translateX(-2000%);
    transition-delay: .7s;
}

.title-block::after {
    content: '';
    position: absolute;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: white;
    top: -8px;
    border-radius: 50%;
    transition: .8s;
    transform: translateX(2000%);
    transition-delay: 1s;
}

.doc:hover .doc-overlay .title-block {
    width: 100%;
    opacity: 1;
    border-radius: 10px;
}

.doc:hover .doc-overlay .title-block::after,
.doc:hover .doc-overlay .title-block::before {
    transform: translateX(0);
}

.doc-title {
    transition: .9s;
    transform: translateY(-2000%);
    opacity: 0;
    transition-delay: .9s;
}

.doc:hover .doc-overlay .doc-title {
    transform: translateY(0);
    opacity: 1;
}

.big-download-btn {
    transition: .9s;
    transform: translateY(400%);
    transition-delay: .7s;
}

.doc:hover .doc-overlay .big-download-btn {
    transform: translateY(0);
}

.paragraph {
    transition: .9s;
    transform: translateY(300%) scale(.2);
    transition-delay: .3s;
    opacity: 0;
}

.doc:hover .doc-overlay .paragraph {
    transform: translateY(0) scale(1);
    opacity: 1;
}

@media screen and (max-width: 767px) {
    .doc {
        width: 100% !important;
    }

    .docs-wrapper {
        max-width: 100% !important;
    }
}