.trial-wrapper {
    background-color: #161741;
    height: 50px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 10px;
    color: white !important;
    width: 200px;
    transform: translateX(-10.5%);
    border-top-left-radius: 5px;
}

.trial-wrapper::before {
    content: '';
    position: absolute;
    background-color: black;
    height: 5px;
    width: 4px;
    bottom: -5px;
    left: -0.01px;
    border-bottom-left-radius: 5px;
}

.pricing-top-wrapper {
    min-height: 130px;
    background: linear-gradient(100deg, #161741, #23245f);
    overflow: hidden;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    /* clip-path: polygon(0 0, 100% 0, 100% 90%, 53.5% 100%, 50% 95%, 47% 100%, 0 90%); */
}

.get-started-title {
    width: 100%;
    font-size: 20px;
    margin-bottom: 20px !important;
}

.get-started-sub-title {
    width: 40%;
}

.deco {
    border-radius: 20px;
    width: 350px;
    height: 350px;
    background-color: #5055a2;
    transform: rotate(-10deg);
    bottom: -150px;
    right: -150px
}


.deco::before {
    content: '';
    position: absolute;
    border-radius: 20px;
    width: 450px;
    height: 450px;
    background-color: #161741;
    transform: rotate(-2deg);
    bottom: -150px;
    right: -150px
}


.deco::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #a1a4d1;
    opacity: .3;
    bottom: 300px;
    right: 500px
}

.dots-wrapper {
    grid-template-columns: repeat(17, 1fr);
    z-index: -1;
    display: none !important;
}

.dot {
    transition: 3s;
    border-radius: .1;
    background-color: white;
    opacity: .6;
    transform: rotate(45deg);
}

.package-wrapper {
    min-height: 397px;
    background-color: white;
    transition: .8s;
}

.package-title {
    color: rgb(58, 58, 58);
    font-size: 23px;
    padding-bottom: 3px;
}


input[type="checkbox"] {
    accent-color: #4d31a8;
    border-radius: 10px;
}

.collapsed {
    height: 70px !important;
    min-height: 70px !important;
    overflow: hidden;
}

.collaps-arrow {
    transition: .5s;
}

.arrow-collaps {
    transform: rotate(-180deg);
}

.form-card {
    width: 80%;
}

/* submission loader  */
.sl-wrapper {
    width: 100%;
    height: 100dvh;
    z-index: 100;
    background-color: rgb(22, 23, 65, 50%);
    position: fixed;
    top: 0;
    left: 0;
}

.loader-inner {
    transition: .5s;
    animation: animate-loader 2s linear infinite;
}

@keyframes animate-loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.unfold {
    transition: .5s;
    animation: unfold 1s linear;
}

@keyframes unfold {
    from {
        min-height: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
    }

}

.license-content {
    height: 70vh;
    overflow-y: auto;
    background-color: #f6f6f6;
    /* color: white !important; */
}

.steps-wrapper {
    overflow: hidden;

}

.steps-container {
    transition: .9s;
    border-radius: 6px;
    overflow: hidden;
}

.steps-on-kyc {
    width: 64% !important;
}

.p-step {
    color: #161741;
    background-color: rgb(224, 224, 224);
    transition: .5s;
}

.p-step.active {
    color: white;
    background-color: #161741;
}

.p-step .index {
    background-color: #161741;
    color: white;
}

.p-step.active .index {
    background-color: white;
    color: #161741;
}

.p-step-clip-1 {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    clip-path: polygon(50% 0, 97% 0, 100% 50%, 97% 100%, 0 100%, 0% 50%, 0 0);
}

.p-step-clip-2 {
    clip-path: polygon(50% 0, 97% 0, 100% 50%, 97% 100%, 0 100%, 3% 50%, 0 0);
    transform: translateX(-7px);
}

.p-step-clip-3 {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    clip-path: polygon(50% 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 2.5% 50%, 0 0);
    transform: translateX(-14px);
    width: 110%;
}

@media screen and (max-width:734px) {
    .package-list-grid {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media screen and (max-width:580px) {
    .package-list-grid {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media screen and (max-width:768px) {
    .trial-wrapper {
        font-size: 12px !important;
        width: 170px;
        transform: translateX(-4px);
    }

    .steps-wrapper .steps-wrapper-grid {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .step-description {
        display: none;
    }

    .p-step {
        clip-path: none !important;
        border-right: 1px white solid;
    }

    .totals-card {
        grid-column: span 2 !important;
    }

    .form-card {
        width: 100%;
        padding: 10px;
        background-color: white;
        padding-right: 5px;
        border: none !important;
    }

    .license-wrapper {
        padding: 0 !important;
        width: 100%;
    }

    .license-title-wrapper {
        width: 90% !important;
    }

    .license-title-btns {
        display: none;
    }

    .license-content {
        width: 90%;
        height: max-content !important;
        margin-bottom: 20px;
    }

    .license-title-btn-smobile {
        display: flex !important;
    }

    .muuted-grid {
        display: none !important;
    }

    .form-btns {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}