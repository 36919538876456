.navbar {
    background-color: transparent;
    color: white;
    z-index: 300;
    top: 0;
    left: 0;
    transition: 1.2s;
}

.freeze {
    overflow: hidden !important;

}

.scrolled {
    background-color: #161741 !important;
}

.mobile-nav-toggler {
    background-color: transparent !important;
    height: 40px !important;
}

.nav-toggler {
    top: 80px;
    left: 0;
    background-color: rgb(22, 23, 65, 90%);
    z-index: 500;
    overflow: hidden;
    transition: 1s;
    height: 0;
}

.nav-toggler-expanded {
    height: calc(100vh - 80px);
}

.mobile-link {
    font-weight: 800;
    margin-bottom: 15px;
}


@media screen and (max-width: 768px) {
    .p-0 {
        padding: 0 !important;
    }

    .p-r-10 {
        padding-right: 10px;
    }

    .mx-95 {
        width: 95% !important;
    }

    .center {
        display: flex !important;
        justify-content: center;
        align-items: center !important;
    }

    .maxmize-inner {
        width: 90% !important;
        padding: 0 !important;
    }

    .maxmize-inner-full {
        width: 100% !important;
    }

    .navbar {
        position: relative !important;
        background-color: #161741 !important;
    }



    .nav-btns,
    .nav-links {
        display: none !important;
    }

    .mobile-btns {
        display: flex !important;
    }
}