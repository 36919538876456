html {
    scroll-behavior: smooth;
}
.about {
    padding-top: 6rem;
    background-color: #151740;
}
.cards-ab {
    text-align: center;
    margin-top: 3rem;
}
.cards-ab h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #fff;
    padding-bottom: 2rem;
}
.about-cards {
    position: relative;
    padding-top: 6rem;
    /* height: ; */
    background-color: #fff;
    margin-top: 2rem;
}
.about-cards::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 50px solid #151740;
    border-right: 230px solid transparent;
}
.cards-holder {
    height: 220px;
    width: 67%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #fff;
    margin: auto;
}
.cards-holder .card {
    position: relative;
    border-radius: .4rem;
    background-color: #fff;
    box-shadow: 0 2px 5px #15174078;
    height: 80%;
    width: 32%;
    padding: 1rem;
}
.cards-holder .card::before {
    content: '';
    position: absolute;
    top: .7rem;
    left: 1rem;
    height:  .9rem;
    width:  .9rem;
    border-radius: 50%;
    border: solid thin #15174056;
}
.cards-holder .card h3 {
    text-transform: capitalize;
    padding: 1rem;
    font-weight: 600;
    font-size: 1.1rem;
}
.cards-holder .card p {
    font-size: .9rem;
    font-weight: 400;
    padding: 0 .5rem;
}
.cards-holder .card:nth-child(2) {
    height: 89%;
    transform: scaleZ(1.5) scaleX(1.06) scaleY(1.06);
    z-index: 2;
}
.info {
    height: max-content;
    width: 100%;
    background-color: #fff;
}
.info .data {
    width: 67%;
    margin: auto;
    padding: 2rem 0;
    padding-bottom: 4rem;
}
.info .data h2 {
    position: relative;
    color: #151740;
    font-size: 2rem;
    padding-top: 8rem;
    text-align: center;
    padding-bottom: 1.5rem;
}
.info .data h2::before {
    content: '';
    position: absolute;
    top: 3rem;
    left: 43%;
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-top: 30px solid #151740;
}
.info .data > p {
    display: block;
    padding: .7rem .5rem;
}
@media screen and (max-width: 992px) {
    .about {
        width: 100vw;
    }
    .about .about-cards {
        height: unset;
        height: max-content;
    }
    .about .cards-holder {
        height: unset;
        width: 100%;
        flex-wrap: wrap;
    } 
    .cards-holder .card {
        margin-top: 1.5rem;
        width: unset;
        height: unset;
        width: 90%;
        height: max-content;
        padding: 1.5rem 1rem;
    }
    .cards-holder .card:nth-child(2) {
        height: unset;
        transform: unset;
        z-index: unset;
    }
    .info .data {
        width: 90%;
    }
    .info .data h2::before {
        left: 30%;
    }
}

@media screen and (max-width: 480px) {
    .data {
        width: 98%;
    }
}