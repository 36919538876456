.spinner {
    transition: .5s;
    animation: animate-spinner 1s linear infinite;
    border-radius: 50%;
    border-width: 4px;
}

@keyframes animate-spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}