table {
    margin-top: 5px;
    margin-bottom: 15px;
}

thead tr {
    border-radius: 10px;
    height: 50px;
    background-color: rgb(250, 248, 252);
}

th:nth-child(1),
td:nth-child(1) {
    padding-left: 7px;
}

tbody tr {
    height: 50px;
    border-bottom: 1px rgb(232, 227, 236) solid;
}