.link {
    transition: .5s;
    color: white;
}

.welcome-wrapper {
    clip-path: polygon(50% 4%, 53% 0, 100% 0, 100% 100%, 0 100%, 0 0, 47% 0);
}

.welcome-card {
    clip-path: polygon(0 0, 100% 0, 100% 86%, 58% 100%, 50% 95%, 44% 100%, 0 86%);
    z-index: -1;
}

.why-card-4 {
    color: white !important;
    background: linear-gradient(-120deg, #0f0b5d, #2f64b9, #0f0b5d);
}

.why-card-4 p {
    color: white !important;
}

.benefits-wrapper {
    clip-path: polygon(50% 0, 100% 4%, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 4%);
}

.overlay {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.overlay-anim-wrapper {
    overflow: hidden;
    min-height: 200px;
    height: max-content;
}

.overlay-inner {
    overflow: hidden;
    transition: .5s;
    animation: an 2s linear;

}

.sentence-wrapper:nth-child(1) {
    margin-left: 0 !important;
}

.overlay-title {
    font-size: 50px;
    font-weight: 900;
    line-height: 60px;
    transition: .5s;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    transition: .5s;
    height: max-content;
}

.overlay-content {
    transition: .5s;
}



@keyframes an {
    from {
        transform: translateY(30%);
    }

}

span.animated {
    opacity: 0;
    transition: .7s;
    transform: translateY(50%) rotate(25deg) skew(40deg);
    color: #0f0b5d;
    display: inline-block;
}

span.show {
    opacity: 1;
    transform: translateY(0%) rotate(0) skew(0);
    color: white;
}

@media screen and (max-width:1100px) {
    .overlay-btns {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 20px;
    }

    .overlay-anim-wrapper {
        min-height: 200px !important;
    }

    .overlay-btns button {
        width: 100% !important;
    }

    .why-list-grid {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .benefits-grid {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media screen and (max-width:800px) {
    .why-list-grid {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media screen and (max-width:580px) {
    .overlay-btns {
        display: flex !important;
    }

    .why-list-grid {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .benefits-grid {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media screen and (max-width: 768px) {
    .overlay-btns {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .overlay-btns button:nth-child(1) {
        margin-bottom: 20px;
    }

    .overlay-anim-wrapper {
        height: 350px;
    }

    .overlay-title {
        font-size: 37px;
        line-height: 45px;
    }

    .welcome-wrapper {
        clip-path: none;
    }

    .welcome-card {
        clip-path: none;
    }

    .welcome-title-wrapper {
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .welcome-title {
        text-align: center;
        width: 100%;
        font-size: 40px;
    }

    .why-use {
        text-align: left !important;
    }

    .benefits-wrapper {
        clip-path: none;
    }

    .benefits-title {
        font-size: 37px;
    }

    .benefit-pill {
        display: none !important;
    }

    .benefit-content {
        margin-left: 5px !important;
    }

}