@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f6;
  overflow-x: hidden;
}


.prose-base :where(h3):not(:where([class~="not-prose"] *)) {
  /* font-size: 1.25em; */
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  line-height: normal !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}