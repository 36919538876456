@media screen and (max-width:768px) {
    .footer-links {
        justify-content: flex-start;
    }

    .terms {
        display: none;
    }

    .powered>* {
        font-size: 11px !important;
    }
}